import { render, staticRenderFns } from "./WheelChangeWheelList.vue?vue&type=template&id=47482506&scoped=true&"
import script from "./WheelChangeWheelList.vue?vue&type=script&lang=ts&"
export * from "./WheelChangeWheelList.vue?vue&type=script&lang=ts&"
import style0 from "./WheelChangeWheelList.vue?vue&type=style&index=0&id=47482506&scoped=true&lang=sass&"
import style1 from "./WheelChangeWheelList.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47482506",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSimpleTable,VTextField})
