

























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import _ from 'lodash'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TyreHotelImage from '@/models/tyrehotel/TyreHotelImage'
import NewOrEditImageDialog from '@/components/tyre-hotel/image-data/NewOrEditImageDialog.vue'
import FullImageDialog from '@/components/tyre-hotel/image-data/FullImageDialog.vue'

@Component({
  components: { FullImageDialog, NewOrEditImageDialog },
})
export default class ImagesData extends Vue {
  public $refs: Vue['$refs'] & {
    uploadNewImageForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  private loadedData = false

  private uploadImageDialogVisible = false
  private uploadImageData = {
    image: null,
    wheel: null,
  }

  private fullImageDialog = false
  private fullImage: TyreHotelImage = null

  private deleteConfirmationDialog = false
  private deleteImageId = null
  private deleteLoading = false

  private rules = {
    imageInput: null,
  }

  private placements = [
    {
      value: 'LeftFront',
      text: this.$t('c:tyre-hotel:Left Front'),
    },
    {
      value: 'RightFront',
      text: this.$t('c:tyre-hotel:Right Front'),
    },
    {
      value: 'LeftRear',
      text: this.$t('c:tyre-hotel:Left Rear'),
    },
    {
      value: 'RightRear',
      text: this.$t('c:tyre-hotel:Right Rear'),
    },
  ]

  private tyreHotelImages: Array<TyreHotelImage> = []
  private groupedImages: Record<string, TyreHotelImage[]> = {}
  private imageGroups = []

  private filters = {
    tyreHotelWheelSetId: null,
    placement: null,
    tyreHotelWheelChangeId: null,
  }

  private created() {
    this.getImages()
  }

  private getImages() {
    // @todo handle per page
    this.loadedData = false
    this.$axios
      .get('/v4/site/tyre-hotels-v2/image?tyreHotelId=' + this.tyreHotel.id + '&perPage=50000')
      .then((response) => {
        this.tyreHotelImages = []
        response.data.data.forEach((imageData: Record<string, unknown>) => {
          this.tyreHotelImages.push(new TyreHotelImage(imageData))
        })
        this.loadedData = true
      })
  }

  @Watch('tyreHotelImages')
  @Watch('filters', { deep: true })
  private filterImages() {
    const currentImages = this.tyreHotelImages.filter(
      (tyreHotelImage) =>
        (this.filters.tyreHotelWheelSetId === null ||
          tyreHotelImage.tyreHotelWheelSetId === this.filters.tyreHotelWheelSetId) &&
        (this.filters.placement === null || tyreHotelImage.placement === this.filters.placement) &&
        (this.filters.tyreHotelWheelChangeId === null ||
          tyreHotelImage.tyreHotelWheelChangeId === this.filters.tyreHotelWheelChangeId),
    )

    this.groupedImages = _.groupBy(currentImages, 'tyreHotelWheelSetId')
    this.imageGroups = Object.keys(this.groupedImages)
  }

  private getTyreHotelWheelSetNameById(tyreHotelWheelSetId) {
    tyreHotelWheelSetId = parseInt(tyreHotelWheelSetId)

    const tyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets.find(
      (tyreHotelWheelSet) => tyreHotelWheelSet.id === tyreHotelWheelSetId,
    )

    if (tyreHotelWheelSet !== undefined) {
      return tyreHotelWheelSet.name
    } else {
      return 'UNKNOWN'
    }
  }

  protected openFullImage(image: TyreHotelImage): void {
    this.fullImageDialog = true
    this.fullImage = image
  }

  protected newOrEditImage(item: TyreHotelImage | null = null): void {
    this.uploadImageData = {
      image: item,
      wheel: null,
    }
    this.uploadImageDialogVisible = true
  }

  private deleteImageDialog(image) {
    this.deleteImageId = image.id
    this.deleteConfirmationDialog = true
  }

  private confirmDelete() {
    this.deleteLoading = true

    this.$axios
      .delete('/v4/site/tyre-hotels-v2/image/' + this.deleteImageId)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully deleted') as string,
          title: this.$t('c:common:Success') as string,
        })

        this.getImages()
        this.deleteConfirmationDialog = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error deleting the image')
      })
      .finally(() => {
        this.deleteLoading = false
      })
  }

  private onComponentChanged(): void {
    this.$emit('onComponentChanged')
  }

  private getTyreHotelWheelChangeName(id: number): string {
    const wheelChange = this.tyreHotel.tyreHotelWheelChanges.find(
      (tyreHotelWheelChange) => tyreHotelWheelChange.id === id,
    )

    return wheelChange.name
  }

  private setPrimaryImage(image) {
    this.loadedData = false
    image.isPrimary = true
    this.$axios
      .put('/v4/site/tyre-hotels-v2/image/' + image.id, image)
      .then(() => {
        this.getImages()
        vxm.alert.success({
          title: this.$t('c:tyre-hotel:Successfully set image as primary') as string,
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error updating image')
      })
      .finally(() => {
        this.loadedData = true
      })
  }
}
