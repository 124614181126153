





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeToLocalDateTime } from '@/utils/dateUtils'
import TyreHotelWheel from '@/models/tyrehotel/TyreHotelWheel'
import { vxm } from '@/store'
import { Debounce } from 'lodash-decorators'

@Component({
  methods: { formatDateTimeToLocalDateTime, appendSitePrefix },
})
export default class WheelChangeWheelList extends Vue {
  @Prop({ type: Array, required: true })
  private wheels: Array<TyreHotelWheel>

  @Prop({ type: Boolean, required: false })
  private showTreadDepths: boolean

  // todo: Ideally we should let this happen in TyreWheelDesktop
  // which already has code for dealing with tread depth changes.
  // But since it uses handleInput() instead of Watch() that would mean
  // emitting event up and then propping it down again - very messy.
  // For now, live with the fact that "Saved at.." is not shown on wheels,
  // and later try to avoid handleInput() in TyreWheelDesktop and instead use Watch(),
  // then this should resolve itself without the need for the save-function below.
  @Debounce(200)
  private onTreadDepthChange(wheel: TyreHotelWheel) {
    const data = { treadDepth: wheel.treadDepth }
    this.$axios
      .patch('/v4/site/tyre-hotels-v2/wheel/' + wheel.id, data)
      .then(() => {
        // saved
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving tread depth')
      })
  }
}
