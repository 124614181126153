






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { appendSitePrefix } from '@/utils/routeUtils'
import BookingData from '@/components/tyre-hotel/BookingData.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'

@Component({
  components: { BookingData },
  methods: { appendSitePrefix },
})
export default class OrderDetails extends Vue {
  @Prop({ type: TyreHotel, required: false })
  private tyreHotel: TyreHotel

  private getOrderPaidClass(): string {
    const o = this.tyreHotel.lastTyreHotelWheelChange.order
    if (o.isPaid || o.isBilled) {
      return 'green--text'
    } else if (o.isCancelled) {
      return 'red--text'
    }
    return ''
  }

  private onComponentChanged() {
    this.$emit('onComponentChanged')
  }
}
