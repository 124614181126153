




























































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ItemTags from '@/components/tag/ItemTags.vue'
import { appendSitePrefix } from '@/utils/routeUtils'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'
import SmsDialog from '@/components/notification/SmsDialog.vue'
import EmailDialog from '@/components/notification/EmailDialog.vue'

@Component({
  components: { EmailDialog, SmsDialog, ItemTags },
})
export default class DetailsData extends Vue {
  $refs: Vue['$refs'] & {
    changeCarForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: Object, required: true })
  private tyreHotel: any

  @Prop({ type: Array, required: true })
  private locations: Array<any>

  private appendSitePrefix = appendSitePrefix

  private changeCarId = null
  private filteredCars = []

  private licenseplateInput = ''

  private changeCarLoading = false
  private changeCarDialog = false

  private smsDialog = false
  private emailDialog = false
  private emailDialogRecipient = null
  private smsDialogRecipient = null

  private editWheelChangeLocation = false
  private editWheelChangeLocationId: number = null
  private editWheelChangeLocationSaving = false

  private expandedRows = []

  @Watch('licenseplateInput')
  @Debounce(300)
  private onLicensePlateInputChange() {
    if (this.licenseplateInput && this.licenseplateInput.length >= 3) {
      this.$axios
        .get(`/v4/site/cars/search?licensePlate=${this.licenseplateInput}`)
        .then((responseCarList) => {
          this.filteredCars = responseCarList.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error reading cars')
        })
    }
  }

  private changeCar() {
    if (this.$refs.changeCarForm.validate()) {
      this.changeCarLoading = true
      const data = {
        carId: this.changeCarId,
      }
      this.$axios
        .patch(`/v4/site/tyre-hotels-v2/${this.tyreHotel.id}`, data)
        .then(() => {
          this.$emit('changedCar', this.changeCarId)
          this.changeCarDialog = false
          this.$refs.changeCarForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error reading cars')
        })
        .finally(() => {
          this.changeCarLoading = false
        })
    }
  }

  private updateCarFourWheelDrive() {
    const payload = {
      drivingAxles: this.tyreHotel.car.drivingAxles,
    }
    this.$axios
      .patch(`/v4/site/cars/${this.tyreHotel.car.id}`, payload)
      .then(() => {
        // noop
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error updating car')
      })
  }

  private openSmsDialog(type: string) {
    this.smsDialog = true
    if (type === 'customer') {
      this.smsDialogRecipient = this.tyreHotel.car.customer.mobile
    } else if (type === 'driver') {
      this.smsDialogRecipient = this.tyreHotel.car.driver?.mobile
    }
  }

  private openEmailDialog(type: string) {
    this.emailDialog = true
    if (type === 'customer') {
      this.emailDialogRecipient = this.tyreHotel.car.customer.email
    } else if (type === 'driver') {
      this.emailDialogRecipient = this.tyreHotel.car.driver?.email
    }
  }

  private get wheelChangeLocationCode() {
    if (!this.tyreHotel.wheelChangeLocationId || !this.locations) {
      return ''
    }
    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i].id === this.tyreHotel.wheelChangeLocationId) {
        return this.locations[i].code
      }
    }
    return ''
  }

  private saveWheelChangeLocation() {
    const payload = { wheelChangeLocationId: this.editWheelChangeLocationId }
    this.editWheelChangeLocationSaving = true
    this.$axios
      .patch('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id, payload)
      .then((_response) => {
        this.tyreHotel.wheelChangeLocationId = this.editWheelChangeLocationId
        this.editWheelChangeLocation = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving wheel change location')
      })
      .finally(() => {
        this.editWheelChangeLocationSaving = false
      })
  }

  private showEditWheelChangeLocation() {
    this.editWheelChangeLocationId = this.tyreHotel.wheelChangeLocationId
    this.editWheelChangeLocation = true
  }

  private get isWheelChangeOpen() {
    return this.tyreHotel?.lastTyreHotelWheelChange?.isActive
  }

  private toggleRow(index: number) {
    const currentIndex = this.expandedRows.indexOf(index)
    if (currentIndex === -1) {
      this.expandedRows.push(index)
    } else {
      this.expandedRows.splice(currentIndex, 1)
    }
  }
}
