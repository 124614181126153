















import { Vue, Component, Prop } from 'vue-property-decorator'
import WheelChangeData from '@/components/tyre-hotel/WheelChangeData.vue'
import TyreWheelMobile from '@/components/tyre-hotel/tyre-wheel-data/TyreWheelMobile.vue'
import TyreWheelDesktop from '@/components/tyre-hotel/tyre-wheel-data/TyreWheelDesktop.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TreadDepthThresholds from '@/models/tyre/TreadDepthThresholds'

@Component({
  components: { TyreWheelDesktop, TyreWheelMobile, WheelChangeData },
})
export default class TyreWheelData extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  @Prop({ type: Array, required: true })
  private locations: Array<any>

  @Prop({ type: TreadDepthThresholds, required: true })
  private treadDepthThresholds: TreadDepthThresholds

  private get isMobile(): boolean {
    return false
    /*
    switch (this.$vuetify.breakpoint.name) {
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
        return false
      default:
        return true
    }
     */
  }

  private onComponentChanged(): void {
    this.$emit('onComponentChanged')
  }
}
