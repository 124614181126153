import TyreHotelWheel from '@/models/tyrehotel/TyreHotelWheel'

export default class TyreHotelWheelSet {
  public id: number
  public tyreHotelId: number
  public season: string
  public name: string
  public receiveDate: string
  public returnDate: string
  public syncMode: string

  public tyreHotelWheels: Array<TyreHotelWheel> = []

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.season = data.season ? (data.season as string) : null
    this.name = data.name ? (data.name as string) : null
    this.receiveDate = data.receiveDate ? (data.receiveDate as string) : null
    this.returnDate = data.returnDate ? (data.returnDate as string) : null
    this.syncMode = data.syncMode ? (data.syncMode as string) : null

    this.tyreHotelWheels = []
    const tyreHotelWheels = data.tyreHotelWheels ? (data.tyreHotelWheels as Array<Record<string, unknown>>) : []
    for (let i = 0; i < tyreHotelWheels.length; i++) {
      this.tyreHotelWheels.push(new TyreHotelWheel(tyreHotelWheels[i]))
    }
  }

  public clone(): TyreHotelWheelSet {
    const result = new TyreHotelWheelSet()
    Object.assign(result, this)
    return result
  }

  public get isOnCar(): boolean {
    return this.returnDate !== null
  }

  public get isOnHotel(): boolean {
    return this.returnDate === null
  }

  public get isWinter(): boolean {
    return this.season === 'Winter'
  }

  public get usedAllPlacements(): boolean {
    return this.getPossiblePlacements().length === 0
  }

  public getPossiblePlacements(): Array<{ text: string; value: string }> {
    const usedPlacements = this.tyreHotelWheels.flat().map((item) => item.placement)

    const options = []
    for (const possibleTyrePlacement of TyreHotelWheel.tyrePlacements) {
      if (!usedPlacements.includes(possibleTyrePlacement)) {
        options.push({
          text: possibleTyrePlacement,
          value: possibleTyrePlacement,
        })
      }
    }

    return options
  }
}
