







































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import WheelUtils from '@/components/tyre-hotel/tyre-wheel-data/WheelUtils.vue'
import WheelChangeData from '@/components/tyre-hotel/WheelChangeData.vue'
import TyreHotelWheel from '@/models/tyrehotel/TyreHotelWheel'
import TyreHotel from '@/models/tyrehotel/TyreHotel'

@Component({
  components: { WheelChangeData },
  mixins: [WheelUtils],
})
export default class TyreWheelMobile extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  private wheelTreadDepthDialog = false
  private tyreDamagesDialog = false
  private rimDamagesDialog = false
  private tpmsDialog = false
  private wheelDetailsDialog = false

  private currentWheel = null
  private currentWheelIndex = null

  private expandedRows = []

  private headers = [
    {
      text: 'Placement',
      value: 'placement',
    },
    {
      text: 'Tread Depth',
      value: 'treadDepth',
    },
    {
      text: 'Dimension',
      value: 'dimension',
    },
    {
      text: 'Icons',
      value: 'icons',
    },
    {
      text: '',
      value: 'data-table-expand',
    },
  ]

  mounted() {
    if (window.screen.orientation.type.includes('landscape')) {
      window.screen.orientation.lock('portrait')
    }
  }

  private openDialog(tyreHotelWheel: TyreHotelWheel, dialogName: string) {
    this.currentWheel = tyreHotelWheel
    this[dialogName] = true
  }

  private toggleExpand(index: number) {
    if (this.expandedRows.includes(index)) {
      this.expandedRows = this.expandedRows.filter((rowIndex) => rowIndex !== index)
    } else {
      this.expandedRows.push(index)
    }
  }
}
