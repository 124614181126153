import i18n from '@/i18n'

export default class TyreHotelImage {
  public id: number
  public tyreHotelId: number
  public tyreHotelWheelSetId: number
  public tyreHotelWheelChangeId: number
  public placement: string
  public comment: string
  public isPrimary: boolean
  public treadDepthReadingId: number
  public originalUrl: string
  public bigUrl: string
  public thumbUrl: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.tyreHotelWheelSetId = data.tyreHotelWheelSetId ? (data.tyreHotelWheelSetId as number) : null
    this.tyreHotelWheelChangeId = data.tyreHotelWheelChangeId ? (data.tyreHotelWheelChangeId as number) : null
    this.placement = data.placement ? (data.placement as string) : null
    this.comment = data.comment ? (data.comment as string) : null
    this.isPrimary = data.isPrimary ? (data.isPrimary as boolean) : false
    this.treadDepthReadingId = data.treadDepthReadingId ? (data.treadDepthReadingId as number) : null
    this.originalUrl = data.originalUrl ? (data.originalUrl as string) : null
    this.bigUrl = data.bigUrl ? (data.bigUrl as string) : null
    this.thumbUrl = data.thumbUrl ? (data.thumbUrl as string) : null
  }

  private get placementText(): string | null {
    let result = null
    if (this.placement === 'LeftFront') {
      result = i18n.t('c:tyre-hotel:Left Front')
    } else if (this.placement === 'RightFront') {
      result = i18n.t('c:tyre-hotel:Right Front')
    } else if (this.placement === 'LeftRear') {
      result = i18n.t('c:tyre-hotel:Left Rear')
    } else if (this.placement === 'RightRear') {
      result = i18n.t('c:tyre-hotel:Right Rear')
    }
    return result
  }

  public clone(): TyreHotelImage {
    const result = new TyreHotelImage()
    Object.assign(result, this)
    return result
  }
}
