































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import TyreHotel from '@/models/tyrehotel/TyreHotel'

@Component({
  components: {},
})
export default class Actions extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  $refs: Vue['$refs'] & {
    changeCarForm: {
      validate: any
      reset: any
    }
    changeProductForm: {
      validate: any
      reset: any
    }
  }

  private fetchedProducts = []

  private reactivateDialog = false
  private reactivateLoading = false

  private terminateDialog = {
    display: false,
    order: false,
    booking: false,
  }

  private terminateLoading = false

  private changeProductDialog = false
  private changeProductLoading = false

  private seasonStockSelectionMode = null
  private productToChange = null

  @Watch('changeProductDialog')
  private onChangeProductDialogChanged() {
    if (this.fetchedProducts.length === 0) {
      this.$axios
        .get('/v4/site/products/tyre-hotel?perPage=100')
        .then((responseProducts) => {
          this.fetchedProducts = responseProducts.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error getting tyre hotel products')
        })
    }
  }

  private terminateTyreHotel(): void {
    this.terminateLoading = true
    this.$axios
      .post('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/terminate', this.terminateDialog)
      .then(() => {
        this.terminateDialog.display = false
        this.$emit('status-change', 'Inactive')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error terminating tyre hotel')
      })
      .finally(() => {
        this.terminateLoading = false
      })
  }

  private reactivateTyreHotel(): void {
    this.reactivateLoading = true
    const data = {
      tyreHotelWheelSetId: this.seasonStockSelectionMode,
    }
    this.$axios
      .post('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/reactivate', data)
      .then(() => {
        this.reactivateDialog = false
        this.$emit('status-change', 'Active')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error reactivating tyre hotel')
      })
      .finally(() => {
        this.reactivateLoading = false
      })
  }

  private changeProduct(): void {
    if (this.$refs.changeProductForm.validate()) {
      this.changeProductLoading = true
      const data = {
        productId: this.productToChange,
      }
      this.$axios
        .patch('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id, data)
        .then(() => {
          this.$emit('product-changed', this.productToChange)
          this.changeProductDialog = false
          this.$refs.changeProductForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error changing the tyre hotel product')
        })
        .finally(() => {
          this.changeProductLoading = false
        })
    }
  }

  private getTerminateDialogOrderLabel(): string {
    const orderId = this.tyreHotel.lastTyreHotelWheelChange?.orderId
    let response = this.$t('c:common:Order')

    if (orderId) {
      response += ' #' + orderId
    }

    return response
  }

  private getTerminateDialogBookingLabel(): string {
    const booking = this.tyreHotel.lastTyreHotelWheelChange?.order?.booking
    let response = this.$t('c:common:Booking')

    if (booking) {
      response += ' "' + booking.displayDate + ' ' + booking.displayStartTime + '"'
    }

    return response
  }
}
