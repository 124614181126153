export default class Product {
  public id: number
  public eontyreDescription: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.eontyreDescription = data.eontyreDescription ? (data.eontyreDescription as string) : null
  }

  public clone(): Product {
    const result = new Product()
    Object.assign(result, this)
    return result
  }
}
