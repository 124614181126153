export default class PositionSummary {
  public positionId: number
  public positionName: string
  public locationId: number
  public locationName: string
  public locationCode: string
  public status: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.positionId = data.positionId ? (data.positionId as number) : null
    this.positionName = data.positionName ? (data.positionName as string) : null
    this.locationId = data.locationId ? (data.locationId as number) : null
    this.locationName = data.locationName ? (data.locationName as string) : null
    this.locationCode = data.locationCode ? (data.locationCode as string) : null
    this.status = data.status ? (data.status as string) : null
  }

  public clone(): PositionSummary {
    const result = new PositionSummary()
    Object.assign(result, this)
    return result
  }
}
