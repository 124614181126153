import TyreHotelWheelSet from '@/models/tyrehotel/TyreHotelWheelSet'
import TyreHotelWheelChange from '@/models/tyrehotel/TyreHotelWheelChange'
import Product from '@/models/product/Product'
import Car from '@/models/car/Car'

export default class TyreHotel {
  public id: number
  public status: string
  public carId: number
  public productId: number
  public storeLocationId: number
  public wheelChangeLocationId: number
  public storageLocationId: number
  public internalComment: string
  public externalComment: string

  public tyreHotelWheelSets: Array<TyreHotelWheelSet> = []
  public activeTyreHotelWheelSet: TyreHotelWheelSet = null

  public tyreHotelWheelChanges: Array<TyreHotelWheelChange> = []
  public lastTyreHotelWheelChange: TyreHotelWheelChange = null

  public product: Product = null

  public car: Car = null

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.status = data.status ? (data.status as string) : null
    this.carId = data.carId ? (data.carId as number) : null
    this.productId = data.productId ? (data.productId as number) : null
    this.storeLocationId = data.storeLocationId ? (data.storeLocationId as number) : null
    this.wheelChangeLocationId = data.wheelChangeLocationId ? (data.wheelChangeLocationId as number) : null
    this.storageLocationId = data.storageLocationId ? (data.storageLocationId as number) : null
    this.internalComment = data.internalComment ? (data.internalComment as string) : null
    this.externalComment = data.externalComment ? (data.externalComment as string) : null

    this.tyreHotelWheelSets = []
    const tyreHotelWheelSets = data.tyreHotelWheelSets
      ? (data.tyreHotelWheelSets as Array<Record<string, unknown>>)
      : []
    for (let i = 0; i < tyreHotelWheelSets.length; i++) {
      this.tyreHotelWheelSets.push(new TyreHotelWheelSet(tyreHotelWheelSets[i]))
    }

    this.product = data.product ? new Product(data.product as Record<string, unknown>) : null
  }

  public clone(): TyreHotel {
    const result = new TyreHotel()
    Object.assign(result, this)
    return result
  }

  public getTyreHotelWheelSetsInHotel(): Array<TyreHotelWheelSet> {
    return this.tyreHotelWheelSets.filter((tyreHotelWheelSet) => !tyreHotelWheelSet.isOnCar)
  }

  public getTyreHotelWheelSetOnCar(): TyreHotelWheelSet {
    const sets = this.tyreHotelWheelSets.filter((tyreHotelWheelSet) => tyreHotelWheelSet.isOnCar)
    return sets.length > 0 ? sets[0] : null
  }

  public getTyreHotelWheelById(id: number): TyreHotelWheelSet {
    for (let i = 0; i < this.tyreHotelWheelSets.length; i++) {
      if (this.tyreHotelWheelSets[i].id === id) {
        return this.tyreHotelWheelSets[i]
      }
    }
    return null
  }

  public get isActive() {
    return this.status === 'Active'
  }

  public get isReadOnly() {
    return !this.isActive
  }

  public get hasActiveWheelChange() {
    return this.lastTyreHotelWheelChange?.isActive
  }

  public activeTyreHotelWheelSetIsDeletable(): boolean {
    if (!this.isActive) {
      return false
    }
    let counter = 0
    for (const tyreHotelWheelSet of this.tyreHotelWheelSets) {
      if (tyreHotelWheelSet.season === this.activeTyreHotelWheelSet.season) {
        counter++
      }
    }

    return counter > 1
  }
}
