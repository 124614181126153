



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TyreHotelWheel from '@/models/tyrehotel/TyreHotelWheel'
import TyreHotelImage from '@/models/tyrehotel/TyreHotelImage'

@Component({})
export default class NewOrEditImageDialog extends Vue {
  public $refs: Vue['$refs'] & {
    uploadNewImageForm: {
      validate: any
      reset: any
    }
    fileInputSelector: {
      $refs: any
    }
  }

  @Prop({ type: TyreHotel, required: false })
  private tyreHotel: TyreHotel

  @Prop({ type: TyreHotelWheel, required: false })
  private tyreHotelWheel: TyreHotelWheel

  @Prop({ type: Number, required: false })
  private wheelSetId: number

  @Prop({ type: Number, required: false })
  private wheelChangeId: number

  @Prop({ type: TyreHotelImage, required: false })
  private image: TyreHotelImage

  @Prop({ type: Boolean, required: true })
  private value: boolean

  private visible = false

  private maxFileSize = 10

  private uploading = false
  private imageFiles = []
  private selectedImageFiles = []
  private uploadImageData = {
    tyreHotelId: this.tyreHotel.id,
    tyreHotelWheelSetId: null,
    tyreHotelWheelChangeId: null,
    placement: null,
    comment: null,
    isPrimary: null,
    imageBase64: null,
  }

  private rules = {
    imageInput: [],
  }

  private placements = [
    {
      value: 'LeftFront',
      text: this.$t('c:tyre-hotel:Left Front'),
    },
    {
      value: 'RightFront',
      text: this.$t('c:tyre-hotel:Right Front'),
    },
    {
      value: 'LeftRear',
      text: this.$t('c:tyre-hotel:Left Rear'),
    },
    {
      value: 'RightRear',
      text: this.$t('c:tyre-hotel:Right Rear'),
    },
  ]

  // On created, start with the visibility given by the v-model prop
  public created(): void {
    if (this.value) {
      this.visible = true
    }
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value) {
    this.visible = value

    if (this.visible) {
      if (this.image) {
        this.rules.imageInput = []

        this.uploadImageData.tyreHotelWheelSetId = this.image.tyreHotelWheelSetId
        this.uploadImageData.placement = this.image.placement
        this.uploadImageData.tyreHotelWheelChangeId = this.image.tyreHotelWheelChangeId
        this.uploadImageData.comment = this.image.comment
        this.uploadImageData.isPrimary = this.image.isPrimary
      } else {
        this.rules.imageInput = [
          (files) =>
            !files ||
            !files.some((file) => file.size > this.maxFileSize * 1024 * 1024) ||
            this.$t('Image size should be less than %s MB per image!').replace('%s', this.maxFileSize.toString()),
          (files) => (!!files && files.length > 0) || this.$t('c:validation:This field is required'),
        ]
      }

      if (this.wheelChangeId) {
        this.uploadImageData.tyreHotelWheelChangeId = this.wheelChangeId
      }

      if (this.wheelSetId) {
        this.uploadImageData.tyreHotelWheelSetId = this.wheelSetId
      }

      if (this.tyreHotelWheel) {
        this.uploadImageData.tyreHotelWheelSetId = this.tyreHotelWheel.tyreHotelWheelSetId
        this.uploadImageData.placement = this.tyreHotelWheel.placement
      }
    } else {
      this.$refs.uploadNewImageForm.reset()
    }
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close() {
    this.$emit('input', false)
    this.$refs.uploadNewImageForm.reset()
  }

  private async upload() {
    if (this.$refs.uploadNewImageForm.validate()) {
      this.uploading = true

      if (this.image) {
        this.$axios
          .put('/v4/site/tyre-hotels-v2/image/' + this.image.id, this.uploadImageData)
          .then(() => {
            // Hard to change only the needed one or know the primary change so reload the whole tyre hotel
            this.$emit('onComponentChanged')
            this.close()
            vxm.alert.success({
              content: this.$t('c:common:Successfully saved') as string,
              title: this.$t('c:common:Success') as string,
            })
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Error uploading an image')
          })
          .finally(() => {
            this.uploading = false
          })
      } else {
        let successfulUploads = 0

        for (const selectedImageFile of this.selectedImageFiles) {
          await this.readFileAsBase64(selectedImageFile)
            .then((base64String) => {
              this.uploadImageData.imageBase64 = base64String
            })
            .catch((error) => {
              vxm.alert.onAxiosError(error, 'Error reading the image')
            })

          await this.$axios.post('/v4/site/tyre-hotels-v2/image', this.uploadImageData).then(() => {
            successfulUploads++
          })
        }

        if (successfulUploads === this.selectedImageFiles.length) {
          await vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
        } else {
          await vxm.alert.error({
            content: this.$t('There was a problem uploading some image(s)') as string,
            title: this.$t('c:common:Error') as string,
          })
        }

        // Hard to change only the needed one or know the primary change so reload the whole tyre hotel
        this.$emit('onComponentChanged')
        this.close()
        this.uploading = false
      }
    }
  }

  private async readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1]
          resolve(base64String)
        } else {
          reject(new Error('Unexpected result type. Expected string.'))
        }
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  private removeFile(index: number) {
    this.selectedImageFiles.splice(index, 1)
  }

  private changedFiles() {
    if (this.imageFiles.length > 0) {
      this.imageFiles.forEach((imageFile) => {
        if (!this.fileExists(imageFile)) {
          this.selectedImageFiles.push(imageFile)
        }
      })
    }
    this.imageFiles = []
  }

  private chooseImages() {
    this.$refs.fileInputSelector.$refs.input.click()
  }

  private getImageName(name: string) {
    return name.length > 10 ? name.substring(0, 10) + '...' : name
  }

  private fileExists(file) {
    return this.selectedImageFiles.find(function(existingFile) {
      return (
        existingFile.name         === file.name &&
        existingFile.lastModified === file.lastModified &&
        existingFile.size === file.size &&
        existingFile.type === file.type
      )
    })
  }

  private get isMobile(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case 'sm':
      case 'md':
      case 'lg':
      case 'xl':
        return false
      default:
        return true
    }
  }
}
