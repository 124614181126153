




















import { Vue, Component, Prop } from 'vue-property-decorator'
import ItemTags from '@/components/tag/ItemTags.vue'

@Component({
  components: { ItemTags },
})
export default class TagsData extends Vue {
  @Prop({ type: Number, required: true })
  private tyreHotelId: number

  @Prop({ type: Boolean, required: false })
  private isReadOnly: boolean
}
