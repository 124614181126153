





























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TyreHotelWheel from '@/models/tyrehotel/TyreHotelWheel'
import { vxm } from '@/store'

@Component({})
export default class WheelSetPositionSelector extends Vue {
  @Prop({ type: Number, required: true })
  private wheelSetId: number

  @Prop({ type: Array, required: true })
  private wheels: Array<TyreHotelWheel>

  @Prop({ type: Array, required: true })
  private locations: Array<any>

  @Prop({ type: Number, required: false })
  private locationId: number

  @Prop({ type: Number, required: false })
  private wheelChangeLocationId: number

  private internalLocationId: number = null
  private internalWheels: Array<{ positionId: number; wheel: TyreHotelWheel }> = []
  private suggestPositionsLoading = false
  private positionsLoading = false
  private saving = false
  private showFull = true
  private error = ''
  private positions = []
  private bookTransport: boolean = null

  private created() {
    this.onExternalLocationId()
    this.onExternalWheels()
    this.showFull = !localStorage.getItem('hide-full-positions')
  }

  @Watch('showFull')
  private onShowFull() {
    localStorage.setItem('hide-full-positions', this.showFull ? '' : '1')
  }

  @Watch('locationId')
  private onExternalLocationId() {
    this.internalLocationId = this.locationId
  }

  @Watch('wheels')
  private onExternalWheels() {
    this.internalWheels = []
    for (let i = 0; i < this.wheels.length; i++) {
      this.internalWheels.push({
        wheel: this.wheels[i],
        positionId: this.wheels[i].positionSummary?.positionId || null,
      })
    }
  }

  @Watch('internalLocationId')
  private getPositions() {
    this.positionsLoading = true
    this.bookTransport = this.checkLocationsRequireTransport()
    this.$axios
      .get('/v4/site/locations/' + this.internalLocationId + '/positions')
      .then((response) => {
        this.positions = response.data.data
        for (let i = 0; i < this.internalWheels.length; i++) {
          if (this.internalWheels[i].positionId && !this.hasPositionId(this.internalWheels[i].positionId)) {
            this.internalWheels[i].positionId = null
          }
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error reading location positions')
      })
      .finally(() => {
        this.positionsLoading = false
        this.suggestPositions()
      })
  }

  private hasPositionId(positionId: number): boolean {
    for (let i = 0; i < this.positions.length; i++) {
      if (this.positions[i].id === positionId) {
        return true
      }
    }
    return false
  }

  private cancel() {
    this.$emit('cancel')
  }

  private save() {
    this.error = ''
    const payload = { bookTransport: this.bookTransport || false, positions: [] }
    for (let i = 0; i < this.internalWheels.length; i++) {
      const w = this.internalWheels[i]
      if (!w.positionId) {
        this.error = this.$t('Must select positions for all wheels')
        return
      }
      payload.positions.push({
        wheelId: w.wheel.id,
        positionId: w.positionId,
      })
    }
    this.saving = true
    this.$axios
      .post('/v4/site/tyre-hotels-v2/set-positions', payload)
      .then((response) => {
        this.$emit('saved', {
          wheelSetId: this.wheelSetId,
          positions: response.data.data.items,
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving positions')
      })
      .finally(() => {
        this.saving = false
      })
  }

  private copyFirstPositionToAll() {
    if (this.suggestPositionsLoading || this.positionsLoading) {
      return
    }
    if (!this.internalWheels || this.internalWheels.length === 0) {
      return
    }
    const firstPositionId = this.internalWheels[0].positionId
    for (let i = 1; i < this.internalWheels.length; i++) {
      this.internalWheels[i].positionId = firstPositionId
    }
  }

  private clearPositions() {
    for (let j = 0; j < this.internalWheels.length; j++) {
      this.internalWheels[j].positionId = null
    }
  }

  private suggestPositions() {
    if (this.suggestPositionsLoading || this.positionsLoading) {
      return
    }
    this.error = ''
    if (!this.internalLocationId) {
      this.error = this.$t('Must select location first')
      return
    }
    this.suggestPositionsLoading = true
    const url =
      '/v4/site/tyre-hotels-v2/wheel-set/' +
      this.wheelSetId +
      '/suggest-positions' +
      '?locationId=' +
      this.internalLocationId
    this.$axios
      .get(url)
      .then((response) => {
        if (response.data.data.success) {
          for (let j = 0; j < this.internalWheels.length; j++) {
            let found = false
            const w = this.internalWheels[j]
            for (let i = 0; i < response.data.data.positions.length; i++) {
              const p = response.data.data.positions[i]
              if (w.wheel.id === p.wheelId) {
                w.positionId = p.positionId
                found = true
              }
            }
            if (!found) {
              w.positionId = null
            }
          }
        } else {
          this.error = response.data.data.message
        }
      })
      .catch((err) => {
        this.error = this.$t('Error suggesting positions')
        vxm.alert.onAxiosError(err, 'Error suggesting positions')
      })
      .finally(() => {
        this.suggestPositionsLoading = false
      })
  }

  private get filteredPositions() {
    if (this.showFull) {
      return this.positions
    }
    const selected = {}
    for (let i = 0; i < this.internalWheels.length; i++) {
      if (!this.internalWheels[i].positionId) {
        continue
      }
      selected[this.internalWheels[i].positionId] = 1
    }
    return this.positions.filter((pos) => {
      return pos.used < 100 || selected[pos.id]
    })
  }

  private getLocation(id: number) {
    if (!id) {
      return null
    }
    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i].id === id) {
        return this.locations[i]
      }
    }
    return null
  }

  private get areLocationsDifferent() {
    return this.wheelChangeLocationId !== this.internalLocationId
  }

  private checkLocationsRequireTransport(): boolean {
    if (!this.areLocationsDifferent) {
      return false
    }
    const changeAreaId = this.getLocation(this.wheelChangeLocationId)?.areaId || null
    const storeAreaId = this.getLocation(this.internalLocationId)?.areaId || null
    const isSameArea = changeAreaId && storeAreaId && changeAreaId === storeAreaId
    return !isSameArea
  }
}
