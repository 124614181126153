import TyreHotelWheelSetHistoryWheel from '@/models/tyrehotel/TyreHotelWheelSetHistoryWheel'

export default class TyreHotelWheelSetHistory {
  public id: number
  public season: string
  public name: string
  public receiveDate: string
  public returnDate: string
  public syncMode: string

  public tyreHotelWheelChangeId: number
  public locationId: number
  public locationCode: string
  public locationName: string

  public tyreHotelWheels: Array<TyreHotelWheelSetHistoryWheel> = []

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.season = data.season ? (data.season as string) : null
    this.name = data.name ? (data.name as string) : null
    this.receiveDate = data.receiveDate ? (data.receiveDate as string) : null
    this.returnDate = data.returnDate ? (data.returnDate as string) : null
    this.syncMode = data.syncMode ? (data.syncMode as string) : null

    this.tyreHotelWheelChangeId = data.tyreHotelWheelChangeId ? (data.tyreHotelWheelChangeId as number) : null
    this.locationId = data.locationId ? (data.locationId as number) : null
    this.locationCode = data.locationCode ? (data.locationCode as string) : null
    this.locationName = data.locationName ? (data.locationName as string) : null

    this.tyreHotelWheels = []
    const tyreHotelWheels = data.tyreHotelWheels ? (data.tyreHotelWheels as Array<Record<string, unknown>>) : []
    for (let i = 0; i < tyreHotelWheels.length; i++) {
      this.tyreHotelWheels.push(new TyreHotelWheelSetHistoryWheel(tyreHotelWheels[i]))
    }
  }

  public clone(): TyreHotelWheelSetHistory {
    const result = new TyreHotelWheelSetHistory()
    Object.assign(result, this)
    return result
  }
}
