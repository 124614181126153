























































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeToLocalDateTime } from '@/utils/dateUtils'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import WheelChangeWheelList from '@/components/tyre-hotel/WheelChangeWheelList.vue'
import WheelSetPositionSelector from '@/components/tyre-hotel/WheelSetPositionSelector.vue'
import NewOrEditImageDialog from '@/components/tyre-hotel/image-data/NewOrEditImageDialog.vue'
import EventPrints from '@/components/printing/EventPrints.vue'

@Component({
  components: { NewOrEditImageDialog, WheelSetPositionSelector, WheelChangeWheelList, EventPrints },
  methods: { formatDateTimeToLocalDateTime, appendSitePrefix },
})
export default class WheelChangeData extends Vue {
  public $refs: Vue['$refs'] & {
    ongoingWheelChangeForm: {
      validate: any
    }
  }

  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  @Prop({ type: Array, required: true })
  private locations: Array<any>

  private actionLoading = false
  private cancelDialogVisible = false
  private changePositionsDialogVisible = 0

  private uploadImageDialogVisible = false

  private change = {
    position: {
      error: '',
      transport: false,
    },
  }

  private selectedReceiveTyreHotelWheelSetId = null
  private selectedReturnTyreHotelWheelSetId = null

  private receiveTyreHotelWheelSet = null
  private returnTyreHotelWheelSet = null

  private cancelData = {
    order: null,
    booking: null,
  }

  private emailCertificate = false
  private emailCertificateTo = null
  private emailCertificateRule = null

  private created() {
    this.onLastTyreHotelWheelChange()

    this.emailCertificateRule = [
      (v) => !!v || this.$t('c:validation:This field is required'),
      (v) => /.+@.+\..+/.test(v) || this.$t('c:validation:E-mail is not valid'),
    ]
  }

  @Watch('lastTyreHotelWheelChange')
  private onLastTyreHotelWheelChange() {
    if (this.tyreHotel.lastTyreHotelWheelChange) {
      if (this.tyreHotel.lastTyreHotelWheelChange.receiveTyreHotelWheelSetId) {
        this.receiveTyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets.find(
          (tyreHotelWheelSet) =>
            tyreHotelWheelSet.id === this.tyreHotel.lastTyreHotelWheelChange.receiveTyreHotelWheelSetId,
        )
      }

      if (this.tyreHotel.lastTyreHotelWheelChange.returnTyreHotelWheelSetId) {
        this.returnTyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets.find(
          (tyreHotelWheelSet) =>
            tyreHotelWheelSet.id === this.tyreHotel.lastTyreHotelWheelChange.returnTyreHotelWheelSetId,
        )
      }
    }
  }

  private get canStartWheelChange() {
    const hasReturnSet = this.selectedReturnTyreHotelWheelSetId || this.defaultReturnWheelSetId
    const hasReceiveSet = this.selectedReceiveTyreHotelWheelSetId || this.defaultReceiveWheelSetId
    return hasReturnSet && hasReceiveSet
  }

  // The set we change from is always the one that is on car
  // (However, should we have only in-hotel sets, it is possible to specify one of those manually)
  private get defaultReceiveWheelSetId() {
    return this.tyreHotel.getTyreHotelWheelSetOnCar()?.id
  }

  // The set we change to is one of those that are in hotel,
  // and if there is only one such, that's always the one.
  private get defaultReturnWheelSetId() {
    if (this.returnTyreHotelWheelSets.length === 1) {
      return this.returnTyreHotelWheelSets[0].id
    }
    return null
  }

  private get defaultFromWheelSetName() {
    return this.getWheelSetName(this.defaultReceiveWheelSetId)
  }

  private get defaultToWheelSetName() {
    return this.getWheelSetName(this.defaultReturnWheelSetId)
  }

  private getWheelSetName(id): string {
    for (let i = 0; i < this.tyreHotel.tyreHotelWheelSets.length; i++) {
      const ws = this.tyreHotel.tyreHotelWheelSets[i]
      if (ws.id === id) {
        return ws.name
      }
    }
    return ''
  }

  private openWheelChange() {
    const data = {
      receiveTyreHotelWheelSetId: this.defaultReceiveWheelSetId
        ? this.defaultReceiveWheelSetId
        : this.selectedReceiveTyreHotelWheelSetId,
      returnTyreHotelWheelSetId: this.defaultReturnWheelSetId
        ? this.defaultReturnWheelSetId
        : this.selectedReturnTyreHotelWheelSetId,
    }

    if (!data.returnTyreHotelWheelSetId) {
      vxm.alert.error(this.$t('c:tyre-hotel:Must select which wheel-set to change to'))
      return
    }

    if (!data.receiveTyreHotelWheelSetId) {
      vxm.alert.error(this.$t('c:tyre-hotel:Must select which wheel-set to change from'))
      return
    }

    if (data.returnTyreHotelWheelSetId === data.receiveTyreHotelWheelSetId) {
      vxm.alert.error(this.$t('c:tyre-hotel:Wheel set to change to and from cannot be the same'))
      return
    }

    this.actionLoading = true
    this.$axios
      .post('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/open-wheel-change', data)
      .then(() => {
        // todo: maybe change last tyre hotel wheel change outside only, not reload whole component
        this.$emit('onComponentChanged')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error opening a wheel change')
      })
      .finally(() => {
        this.actionLoading = false
      })
  }

  private get returnTyreHotelWheelSets() {
    return this.tyreHotel.getTyreHotelWheelSetsInHotel()
  }

  private getEventPrints() {
    return this.$refs.eventPrints as Vue & { getIsEnabled: () => boolean; getPayload: () => never }
  }

  private complete() {
    if (this.$refs.ongoingWheelChangeForm.validate()) {
      this.actionLoading = true
      const completeData = {
        emailCertificateTo: this.emailCertificate ? this.emailCertificateTo : null,
        prints: null,
      }
      const eventPrints = this.getEventPrints()
      if (eventPrints?.getIsEnabled()) {
        completeData.prints = eventPrints.getPayload()
      }
      // tuba
      this.$axios
        .post(
          '/v4/site/tyre-hotels-v2/wheel-change/' + this.tyreHotel.lastTyreHotelWheelChange.id + '/complete',
          completeData,
        )
        .then(() => {
          // todo: maybe change last tyre hotel wheel change outside only, not reload whole component
          // note: must also reload wheels
          this.$emit('onComponentChanged')
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error completing without payment')
        })
        .finally(() => {
          this.actionLoading = false
        })
    }
  }

  private cancel() {
    this.actionLoading = true
    this.$axios
      .post(
        '/v4/site/tyre-hotels-v2/wheel-change/' + this.tyreHotel.lastTyreHotelWheelChange.id + '/cancel',
        this.cancelData,
      )
      .then(() => {
        // todo: maybe change last tyre hotel wheel change outside only, not reload whole component
        this.$emit('onComponentChanged')
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error cancelling')
      })
      .finally(() => {
        this.actionLoading = false
      })
  }

  private showChangePositions() {
    this.changePositionsDialogVisible = new Date().getTime()
  }

  private onSavedPositions(update): void {
    this.changePositionsDialogVisible = 0
    const wheelSet = this.tyreHotel.getTyreHotelWheelById(update.wheelSetId)
    if (!wheelSet) {
      return
    }
    const oldWheels = wheelSet.tyreHotelWheels
    const newWheelsWithPositions = update.positions
    for (let i = 0; i < newWheelsWithPositions.length; i++) {
      const n = newWheelsWithPositions[i]
      for (let j = 0; j < oldWheels.length; j++) {
        const o = oldWheels[j]
        if (o.id === n.wheelId) {
          o.positionSummary = n.positionSummary
        }
      }
    }
    this.tyreHotel.storageLocationId = wheelSet.tyreHotelWheels[0].positionSummary.locationId
  }

  @Watch('tyreHotel.car.driver', { immediate: true })
  @Watch('tyreHotel.car.customer')
  private certificateChange() {
    this.emailCertificateTo = this.tyreHotel.car.driver?.email ?? this.tyreHotel.car.customer?.email
  }

  private setEmailCertificateTo(type: string): void {
    if (type === 'customer') {
      this.emailCertificateTo = this.tyreHotel.car.customer?.email
    } else if (type === 'driver') {
      this.emailCertificateTo = this.tyreHotel.car.driver?.email
    }
  }
}
