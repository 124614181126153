




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import TyreHotelImage from '@/models/tyrehotel/TyreHotelImage'

@Component({
  components: {},
})
export default class FullImageDialog extends Vue {
  @Prop({ type: TyreHotelImage, required: null })
  private image: TyreHotelImage

  @Prop({ type: Boolean, required: true })
  private value: boolean

  private visible = false

  // On created, start with the visibility given by the v-model prop
  public created(): void {
    if (this.value) {
      this.visible = true
    }
  }

  // If the v-model prop is changed from outside, update our visibility
  @Watch('value')
  private onValueChange(value: boolean): void {
    this.visible = value
  }

  // If we programmatically close dialog in here, emit to the v-model outside
  private close(): void {
    this.$emit('input', false)
  }

  private editImage(): void {
    this.$emit('editImage', this.image)
  }
}
