

































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import DocumentIdentifier from '@/models/document/DocumentIdentifier'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import { vxm } from '@/store'
import TyreHotel from '@/models/tyrehotel/TyreHotel'

@Component({
  components: { DocumentDialog },
})
export default class DocumentData extends Vue {
  public $refs: Vue['$refs'] & {
    uploadNewDocumentForm: {
      validate: any
      reset: any
    }
  }

  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  private tyreHotelDocuments = []

  private document = null
  private documentDialogVisible = false

  private newDocumentDialog = false
  private documentFile = null
  private uploading = false
  private uploadDocumentData = {
    tyreHotelId: this.tyreHotel.id,
    name: null,
    fileBase64: null,
  }

  private rules = {
    name: null,
    fileInput: null,
  }

  private deleteConfirmationDialog = false
  private deleteDocumentId = null
  private deleteLoading = false

  private created() {
    this.rules = {
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
      fileInput: [(v) => !!v || this.$t('c:validation:This field is required')],
    }

    this.getDocuments()
  }

  private getDocuments() {
    // Todo: fix perPage
    this.$axios
      .get('/v4/site/tyre-hotels-v2/document?tyreHotelId=' + this.tyreHotel.id + '&perPage=50000')
      .then((response) => {
        this.tyreHotelDocuments = response.data.data
      })
  }

  private async upload() {
    if (this.$refs.uploadNewDocumentForm.validate()) {
      this.uploading = true

      await this.readFileAsBase64(this.documentFile)
        .then((base64String) => {
          this.uploadDocumentData.fileBase64 = base64String
        })
        .catch((error) => {
          vxm.alert.onAxiosError(error, 'Error reading the document')
        })

      this.$axios
        .post('/v4/site/tyre-hotels-v2/document', this.uploadDocumentData)
        .then((response) => {
          this.tyreHotelDocuments.unshift(response.data.data)
          this.$refs.uploadNewDocumentForm.reset()
          this.newDocumentDialog = false
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error uploading a document')
        })
        .finally(() => {
          this.uploading = false
        })
    }
  }

  private async readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1]
          resolve(base64String)
        } else {
          reject(new Error('Unexpected result type. Expected string.'))
        }
      }

      reader.onerror = (error) => {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  private deleteDocumentDialog(document) {
    this.deleteDocumentId = document.id
    this.deleteConfirmationDialog = true
  }

  private confirmDelete() {
    this.deleteLoading = true

    this.$axios
      .delete('/v4/site/tyre-hotels-v2/document/' + this.deleteDocumentId)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully deleted') as string,
          title: this.$t('c:common:Success') as string,
        })

        this.getDocuments()
        this.deleteConfirmationDialog = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error deleting the document')
      })
      .finally(() => {
        this.deleteLoading = false
      })
  }

  private openWorkOrder() {
    this.document = new DocumentIdentifier()
    this.document.type = 'WorkOrderForTyreHotel'
    this.document.params = { action_id: this.tyreHotel.lastTyreHotelWheelChange.id }
    this.documentDialogVisible = true
  }

  private openCertificate() {
    this.document = new DocumentIdentifier()
    this.document.type = 'TyreHotelCertificate'
    this.document.params = { tyreHotelWheelChangeId: this.tyreHotel.lastTyreHotelWheelChange.id }
    this.documentDialogVisible = true
  }

  private openManualFile(fileId: number) {
    this.document = new DocumentIdentifier()
    this.document.type = 'ManualFile'
    this.document.params = { mediaFileId: fileId }
    this.documentDialogVisible = true
  }
}
